<template>
    <b-modal
        id="ModalCreateMedia"
        title="Créer un média"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        size="xl"
        hide-footer
    >
        <FormMedia :context="context" @saved="onSaved" class="mb-2 h-50" />
    </b-modal>
</template>
<script>
import FormMedia from "./FormMedia";
export default {
    components: {
        FormMedia,
    },

    props: {
        context: { type: Object, required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateMedia");
            this.$emit("saved");
        },
    },
};
</script>
