<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1><small>Collection</small> {{ collection.name }}</h1>
            <div class="k-flex-row">
                <b-button
                    size="sm"
                    variant="kalkin-2"
                    v-b-modal.ModalCreateMedia
                >
                    <b-icon-file-earmark-plus />
                    Nouveau média
                </b-button>

                <b-button
                    size="sm"
                    variant="outline-secondary"
                    v-b-modal.ModalUpdateCollection
                >
                    <b-icon-pencil />
                    Modifier la collection
                </b-button>
            </div>
        </div>

        <div class="k-page-body text-left">
            <TableMedias
                id="table-medias"
                perPage="20"
                @select="onMediaSelected"
            />
        </div>

        <ModalUpdateCollection
            :collection="collection"
            @saved="fetchCollection"
        />

        <ModalCreateMedia :context="context" @saved="refreshMedias" />

        <ModalUpdateMedia
            :context="context"
            :media="selectedMedia"
            @saved="refreshMedias"
        />
    </div>
</template>

<script>
import TableMedias from "@/components/misc/admin-medias/TableMedias.vue";
import ModalUpdateCollection from "@/components/misc/admin-medias/ModalUpdateCollection.vue";
import ModalCreateMedia from "@/components/model/media/ModalCreateMedia.vue";
import ModalUpdateMedia from "@/components/model/media/ModalUpdateMedia.vue";
export default {
    components: {
        TableMedias,
        ModalUpdateCollection,
        ModalCreateMedia,
        ModalUpdateMedia,
    },

    data: function() {
        return {
            collection: {},
            selectedMedia: {},
        };
    },

    computed: {
        context() {
            return {
                target: "media",
                collectionId: this.collection.id,
                serviceId: this.collection.service,
                mime: this.collection.mime,
            };
        },
    },

    methods: {
        async fetchCollection() {
            this.collection = await this.$store.dispatch(
                "media/getCollection",
                {
                    collectionId: this.$route.params.collectionId,
                },
            );
        },

        refreshMedias() {
            this.$root.$emit("bv::refresh::table", "table-medias");
        },

        onMediaSelected(media) {
            this.selectedMedia = media;
            this.$bvModal.show("ModalUpdateMedia");
        },
    },

    async beforeMount() {
        await this.fetchCollection();
    },
};
</script>
