<template>
    <b-modal
        id="ModalUpdateCollection"
        title="Modifier une collection de médias"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormCollection
            :collection="collection"
            @saved="onSaved"
            class="mb-2"
        />
    </b-modal>
</template>
<script>
import FormCollection from "./FormCollection";
export default {
    components: {
        FormCollection,
    },

    props: {
        collection: { type: Object, required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalUpdateCollection");
            this.$emit("saved");
        },
    },
};
</script>
