<template>
    <div class="k-flex-col">
        <div class="k-flex-row mt-2">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher un média"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>
            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Taille des aperçu
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="previewSize"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio value="50px">petit</b-form-radio>
                        <b-form-radio value="100px">moyen</b-form-radio>
                        <b-form-radio value="200px">grand</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Médias par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            :id="id"
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="name"
            show-empty
            empty-text="Aucun média"
            empty-filtered-text="Aucune média trouvé"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onMediaSelected"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
            <template #cell(updatedAt)="data">
                {{ data.item.updatedAt | daysSinceISO }}
            </template>
            <template #cell(metadata)="data">
                <template v-if="data.item.tags">
                    <b-badge
                        class="mx-1"
                        variant="secondary"
                        v-for="tag in data.item.tags"
                        :key="tag"
                    >
                        {{ tag }}
                    </b-badge>
                </template>
                <template v-else>
                    <small class="font-italic">aucun</small>
                </template>
            </template>
            <template #cell(url)="data">
                <ImagePreview
                    :width="previewSize"
                    :height="previewSize"
                    fontSize="12px"
                    :url="
                        data.item.url +
                            '?notrack=true&time=' +
                            data.item.updatedAt
                    "
                />
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import ImagePreview from "@/components/ui/item/ImagePreview.vue";
export default {
    components: {
        ImagePreview,
    },

    props: {
        id: { type: String, required: true },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            previewSize: "100px",
            fields: [
                {
                    key: "url",
                    label: "Aperçu",
                    tdClass: "mx-3",
                },
                {
                    key: "name",
                    label: "Nom du média",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold text-left",
                },
                {
                    key: "metadata",
                    label: "Mots-clés",
                    sortable: true,
                    class: "k-tag-list",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
                {
                    key: "access",
                    label: "Accès",
                    sortable: true,
                },
                {
                    key: "updatedAt",
                    label: "Dernier accès",
                    sortable: true,
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "media/countFSPMedias",
                {
                    collectionId: this.$route.params.collectionId,
                    filter: `name substring ${this.filter} or metadata substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            const medias = await this.$store.dispatch("media/getFSPMedias", {
                collectionId: this.$route.params.collectionId,
                filter: `name substring ${this.filter} or metadata substring ${this.filter}`,
                sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                limit: this.perPage,
                offset: this.perPage * (this.currentPage - 1),
            });
            return medias.map(
                c => (
                    (c.tags =
                        c.metadata && c.metadata.tags && c.metadata.tags.length
                            ? c.metadata.tags
                            : null),
                    c
                ),
            );
        },

        onMediaSelected(media) {
            this.$emit("select", media);
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>

<style lang="scss">
.k-tag-list {
    width: max(15vw, 100px);
}
</style>
